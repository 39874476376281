import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "../../../layout/Layout";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import MOrderServices from "../../../apis/Marketplace/MarketplaceOrders/MOrderServices";
import UserInfoDialog from "../../../components/Dialog/UserInfoDialog";
import QuoteDialog from "../../../components/Dialog/QuoteDialog";
import MedicineDialog from "../../../components/Dialog/MedicineDialog";
import Images from "../../../assets/Images";
//* For Logs
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
//* Required Components
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import { getTime, getTimeDate } from "../../../utils/DateFormate";
import { baseUrl } from "../../../../axios";
import { Loading } from "../../../components/UI/Loader";
import { SecondaryButton } from "../../../components/Button/Button";
//* Firebase Import
import { onMessageListener } from "../../../firebase/firebase";
//* MUI Imports
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Avatar,
  IconButton,
  Link,
  CardMedia,
} from "@mui/material";
import moment from "moment";
import PharmaciesServices from "../../../apis/Marketplace/Pharmacies/PharmaciesServices";

const tableHead = [
  "Image",
  "Name",
  "Pharmacy",
  "Category",
  "Price",
  "Quantity",
  "Total Price",
];
const MOrderDetail = () => {
  const { id } = useParams();
  // *For Loading
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Order Detail
  const [orderDetail, setOrderDetail] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [orderNum, setOrderNum] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [placedAt, setPlacedAt] = useState("");
  const [orderLogs, setOrderLogs] = useState([]);
  const [billingInfo, setBillingInfo] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [medImg, setMedImg] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(5);
  // *For Modal
  const [medicineModal, setMedicineModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  //* For Different Image URL's
  const imageURL = productDetail?.result?.prescription?.startsWith("https")
    ? productDetail?.result?.prescription
    : baseUrl + productDetail?.result?.prescription;

  //* Order Details Api
  const getOrderDetail = async () => {
    try {
      const { status, responseCode, data } =
        await MOrderServices.getOrderDetails(id);
      if (status === true && responseCode === 200) {
        setOrderDetail(data);
        setProductDetail(data.result.productDetails);
        setOrderNum(data.result.order_id);
        setOrderStatus(data.result.status.name);
        setPlacedAt(data.result.createdAt);
        setOrderLogs([...data.logs]);
        setBillingInfo(data.billing);
        setUserInfo(data.result.user);
        setAddressInfo(data.result.address);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  console.log("orderDetail=>", orderDetail);

  useEffect(() => {
    getOrderDetail();
  }, []);

  //* Quote Orders
  // const [quoteDialog, setQuoteDialog] = useState(false);
  // const quoteOrder = async (quote, reset) => {
  //   try {
  //     const obj = {
  //       order_id: id,
  //       total_amount: quote.price,
  //       message: quote.message,
  //     };
  //     const { status, responseCode } = await OrderServices.quoteOrder(obj);
  //     if (status === true && responseCode === 200) {
  //       SuccessToaster("Offer Quoted");
  //       setQuoteDialog(!quoteDialog);
  //       reset();
  //       getOrderDetail();
  //     }
  //   } catch (error) {
  //     ErrorToaster(error);
  //   }
  // };

  //* for image download
  const [fetching, setFetching] = useState(false);
  const imgDownload = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.log(error));
  };

  function capitalizeFirstLetter(inputString) {
    return `${inputString.charAt(0).toUpperCase()}${inputString.slice(1)}`;
  }

  //* FireBase for Reload Page
  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ file: Header.js:57 ~ .then ~ payload", payload);
      getOrderDetail();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Fragment>
      <UserInfoDialog
        open={infoModal}
        onClose={() => setInfoModal(!infoModal)}
        action={productDetail?.result?.user}
      />
      <MedicineDialog
        open={medicineModal}
        onClose={() => setMedicineModal(!medicineModal)}
        image={medImg}
        download={imgDownload}
      />
      {/* <QuoteDialog
        open={quoteDialog}
        onClose={() => setQuoteDialog(!quoteDialog)}
        title={"Quote Offer"}
        action={quoteOrder}
        loading={""}
      /> */}
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Marketpalce Order Detail
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs
              currentPage={["Marketplace Order", "Marketplace Order Detail"]}
            />
          </Grid>
        </Grid>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                backgroundColor: colors.white,
                p: 2,
                my: 2,
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">
                Order ID: <b>{orderNum}</b>
              </Typography>
              <Box display="flex" alignItems="center" p={1}>
                <Typography variant="body2" px={1}>
                  Order Status{" "}
                </Typography>
                <Chip
                  label={orderStatus}
                  sx={{
                    backgroundImage: colors.primaryGradient,
                    color: colors.white,
                  }}
                />
              </Box>
              <Typography variant="body1">
                Placed at: <b>{getTimeDate(placedAt)}</b>
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: colors.white,
                    p: 2,
                    borderRadius: "12px",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    Product Detail
                  </Typography>
                  <TableContainer
                    component={Paper}
                    sx={{
                      "&::-webkit-scrollbar": {
                        bgcolor: colors.white,
                        height: "8px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        bgcolor: colors.primary,
                        borderRadius: "10px",
                        cursor: "pointer",
                      },
                      "&.MuiPaper-root": {
                        borderRadius: "12px",
                        mt: 2,
                        boxShadow: 0,
                      },
                    }}
                  >
                    <Table
                      Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          {tableHead.map((cell, index) => (
                            <TableCell key={index}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productDetail.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {row.picture.length > 0 ? (
                                <Avatar
                                  src={baseUrl + row.picture[0]}
                                  alt="medicine"
                                />
                              ) : (
                                <Avatar src={Images.thumbnail} alt="medicine" />
                              )}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.pharmacy_name}</TableCell>
                            <TableCell>{row.category_name}</TableCell>
                            <TableCell>{row.product_price}</TableCell>
                            <TableCell>{row.qty}</TableCell>
                            <TableCell>{row.product_price * row.qty}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    backgroundColor: colors.white,
                    p: 2,
                    borderRadius: "12px",
                    minHeight: "200px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      User Details
                    </Typography>
                    {addressInfo.tag && (
                      <Chip
                        label={addressInfo.tag}
                        sx={{
                          backgroundImage: colors.secondaryGradient,
                          color: colors.white,
                        }}
                      />
                    )}
                  </Box>
                  <Divider flexItem sx={{ my: 1 }} />
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item md={12}>
                      <Box sx={{ my: 2 }}>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Name: </b>
                          {userInfo.name ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Email: </b>
                          {userInfo.email}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Area: </b>
                          {addressInfo.area ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>House/Building: </b>
                          {addressInfo.house_building ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Apt/Room: </b>
                          {addressInfo.apt_room ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Street: </b>
                          {addressInfo.street ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Address: </b>
                          {addressInfo.address ?? "--"}
                        </Typography>
                        {addressInfo.notes && (
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            <b>Notes: </b>
                            {addressInfo.notes ?? "--"}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="space-evenly">
                        <Box>
                          <SecondaryButton
                            title={"View User Docs"}
                            onClick={() => setInfoModal(!infoModal)}
                          />
                        </Box>
                        <Box>
                          <SecondaryButton
                            title={"View Location"}
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/place/${addressInfo.latitude},${addressInfo.longitude}/`,
                                "_blank"
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: colors.white,
                        p: 2,
                        borderRadius: "12px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Billing Details
                        </Typography>
                        {["Confirmed", "Completed"].includes(
                          orderStatus.name
                        ) && (
                          <Chip
                            label={
                              billingInfo.payment_method === "card"
                                ? "Paid Online"
                                : "Cash on delivery"
                            }
                            sx={{
                              backgroundImage: colors.quoted,
                              color: colors.white,
                            }}
                          />
                        )}
                      </Box>
                      <Divider flexItem sx={{ my: 1 }} />
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Amount:</Typography>
                          <Typography>
                            <b>{billingInfo.amount - deliveryCharges}</b>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Delivery: </Typography>
                          <Typography>
                            {/* <b>{billingInfo.delivery_charges}</b> */}
                            <b>{deliveryCharges}</b>
                          </Typography>
                        </Box>
                        {/* <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Tip: </Typography>
                          <Typography>
                            <b>{billingInfo.tip ?? 0}</b>
                          </Typography>
                        </Box> */}
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Discount: </Typography>
                          <Typography>
                            <b>{billingInfo.discount ?? 0}</b>
                          </Typography>
                        </Box>
                        {billingInfo.voucher_code && (
                          <Box
                            sx={{
                              my: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">
                              Voucher Code:{" "}
                            </Typography>
                            <Typography>
                              <b>{billingInfo.voucher_code ?? 0}</b>
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">
                            Total Amount:{" "}
                          </Typography>
                          <Typography>
                            <b>{billingInfo.total_amount}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {orderDetail?.result?.delivery && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: colors.white,
                          p: 2,
                          borderRadius: "12px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body1" fontWeight="bold">
                            Delivery Slot
                          </Typography>
                          {["Confirmed", "Completed"].includes(
                            orderStatus.name
                          ) && (
                            <Chip
                              label={
                                billingInfo.payment_method === "card"
                                  ? "Paid Online"
                                  : "Cash on delivery"
                              }
                              sx={{
                                backgroundImage: colors.quoted,
                                color: colors.white,
                              }}
                            />
                          )}
                        </Box>
                        <Divider flexItem sx={{ my: 1 }} />
                        <Box>
                          <Box
                            sx={{
                              width: "100%",
                              mt: 2,
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">
                              Date & Time:
                            </Typography>
                            <Typography>
                              <b>
                                {moment(
                                  orderDetail?.result?.delivery?.date_time
                                )
                                  .utc()
                                  .format(
                                    orderDetail?.result?.delivery?.nature ===
                                      "instant"
                                      ? "MMM DD, hh:mm a"
                                      : "MMM DD"
                                  )}
                              </b>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              my: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">Slot: </Typography>
                            {orderDetail?.result?.delivery?.nature ===
                            "instant" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography>
                                  <b>Instant</b>
                                </Typography>
                                <Typography
                                  sx={{ color: "#C4C4C4", fontSize: "11px" }}
                                >
                                  With in 4 hours
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography>
                                  <b>
                                    {capitalizeFirstLetter(
                                      orderDetail?.result?.delivery?.slot
                                    )}
                                  </b>
                                </Typography>
                                <Typography
                                  sx={{ color: "#C4C4C4", fontSize: "11px" }}
                                >
                                  {orderDetail?.result?.delivery?.time_range}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Box
              sx={{
                backgroundColor: colors.white,
                p: 2,
                my: 2,
                borderRadius: "12px",
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">
                  Order Logs
                </Typography>
                {/* {["Requested", "Cancelled"].includes(
                  orderDetail?.result?.status?.name
                ) ? (
                  <SecondaryButton
                    title="Quote Order"
                    onClick={() => {
                      setQuoteDialog(!quoteDialog);
                    }}
                  />
                ) : null} */}
              </Box>
              {orderLogs.map((item, index) => (
                <Timeline
                  key={index}
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {getTime(item.createdAt)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: "14px", px: 2 }}>
                      <Typography sx={{ color: colors.primary }}>
                        {item.message ?? "-"}
                      </Typography>
                      <Typography>{item.log ?? "-"}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default MOrderDetail;
