import { get, post, patch, deleted } from '../index';
import NotificationRoutes from './Notification.Routes';

const NotificationServices = {
  countNotification: async (obj) => {
    const data = await get(NotificationRoutes.countNotification);
    return data
  },
  notifications: async (obj) => {
    const data = await get(NotificationRoutes.notifications);
    return data
  },

}

export default NotificationServices