import Login from "./../views/Auth/Login";
import Dashboard from "./../views/Dashboard/Dashboard";
import Order from "./../views/Orders/Order";
import UsersList from "./../views/Users/UsersList";
import OrderDetail from "./../views/Orders/OrderDetail";
import AppSettings from "./../views/Settings/AppSettings";
import ContactDetails from "./../views/Contacts/ContactDetails";
import BlogsList from "./../views/Blogs/BlogsList";
import CreateBlog from "./../views/Blogs/CreateBlog";
import AboutUs from "./../views/AboutUs/AboutUs";
import Conditions from "./../views/Conditions/Conditions";
import DeletedUsers from "../views/Users/DeletedUsers";
import EditBlog from "./../views/Blogs/EditBlog";
import Voucher from "../views/Vouchers/Voucher";
import CreateVoucher from "./../views/Vouchers/CreateVoucher";
import EditVoucher from "./../views/Vouchers/EditVoucher";
import Pharmacies from "../views/Pharmacies";
import CreatePharmacy from "../views/Pharmacies/create-pharmacy";
import UpdatePharmacy from "../views/Pharmacies/update-pharmacy";
import AllocatedPharmacies from "../views/Pharmacies/allocated-pharmacies";
import AllocationDetail from "../views/Pharmacies/allocation-detail";
import Categories from "../views/Marketplace/Categories/Categories";
import CreateCategory from "../views/Marketplace/Categories/CreateCategory";
import EditCategory from "../views/Marketplace/Categories/EditCategory";
import MPharmacies from "../views/Marketplace/Pharmacies/MPharmacies";
import CreateMPharmacy from "../views/Marketplace/Pharmacies/CreateMPharmacy";
import EditMPharmacy from "../views/Marketplace/Pharmacies/EditMPharmacy";
import Offers from "../views/Marketplace/Offers/Offers";
import CreateOffer from "../views/Marketplace/Offers/CreateOffer";
import EditOffer from "../views/Marketplace/Offers/EditOffer";
import Products from "../views/Marketplace/Products/Products";
import CreateProduct from "../views/Marketplace/Products/CreateProduct";
import EditProduct from "../views/Marketplace/Products/EditProduct";
import MOrder from "../views/Marketplace/MarketplaceOrders/MOrder";
import MOrderDetail from "../views/Marketplace/MarketplaceOrders/MOrderDetail";

const AdminRoutes = [
  // {
  //   path: '/login',
  //   component: <Login />
  // },
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/orders",
    component: <Order />,
  },
  {
    path: "/order/:id",
    component: <OrderDetail />,
  },
  {
    path: "/pharmacies",
    component: <Pharmacies />,
  },
  {
    path: "/allocated-pharmacies",
    component: <AllocatedPharmacies />,
  },
  {
    path: "/create-pharmacy",
    component: <CreatePharmacy />,
  },
  {
    path: "/update-pharmacy",
    component: <UpdatePharmacy />,
  },
  {
    path: "/allocation-detail/:id",
    component: <AllocationDetail />,
  },
  {
    path: "/users",
    component: <UsersList />,
  },
  {
    path: "/deleted-users",
    component: <DeletedUsers />,
  },
  {
    path: "/vouchers",
    component: <Voucher />,
  },
  {
    path: "/create-voucher",
    component: <CreateVoucher />,
  },
  {
    path: "/edit-voucher",
    component: <EditVoucher />,
  },
  {
    path: "/app-setting",
    component: <AppSettings />,
  },
  {
    path: "/contact-detail",
    component: <ContactDetails />,
  },
  {
    path: "/blogs",
    component: <BlogsList />,
  },
  {
    path: "/create-blog",
    component: <CreateBlog />,
  },
  {
    path: "/edit-blog",
    component: <EditBlog />,
  },
  {
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    path: "/terms-conditions",
    component: <Conditions />,
  },
  {
    path: "/marketplace/categories",
    component: <Categories />,
  },
  {
    path: "/marketplace/categories/createcategory",
    component: <CreateCategory />,
  },
  {
    path: "/marketplace/categories/editcategory",
    component: <EditCategory />,
  },
  {
    path: "/marketplace/offers",
    component: <Offers />,
  },
  {
    path: "/marketplace/offers/createoffer",
    component: <CreateOffer />,
  },
  {
    path: "/marketplace/offers/editoffer",
    component: <EditOffer />,
  },
  {
    path: "/marketplace/pharmacies",
    component: <MPharmacies />,
  },
  {
    path: "/marketplace/pharmacies/createpharmacy",
    component: <CreateMPharmacy />,
  },
  {
    path: "/marketplace/pharmacies/editpharmacy",
    component: <EditMPharmacy />,
  },
  {
    path: "/marketplace/products",
    component: <Products />,
  },
  {
    path: "/marketplace/products/createproduct",
    component: <CreateProduct />,
  },
  {
    path: "/marketplace/products/editproduct",
    component: <EditProduct />,
  },
  {
    path: "/marketplace/orders",
    component: <MOrder />,
  },
  {
    path: "/marketplace/order/:id",
    component: <MOrderDetail />,
  },
];

export default AdminRoutes;
