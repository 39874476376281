import React, { Fragment, memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
  Box,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// *Imported Components
import Images from "../assets/Images";
import colors from "./../styles/colors";
import Navigation from "./../Navigation";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 0, 0, 1),
  margin: "10px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const drawerWidth = 270;

const SideNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [expand, setExpand] = useState([]);

  // *For Active
  const handleSubChildActive = (item) => {
    return (
      item.childPath?.indexOf(pathname) !== -1 &&
      item.childPath?.indexOf(pathname)
    );
  };

  // const handleToggleSubMenu = (index) => {
  //   setExpand((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  const handleToggleSubMenu = (index) => {
    setExpand((prevState) => {
      const updatedExpand = [...prevState];
      updatedExpand[index] = !updatedExpand[index];
      return updatedExpand;
    });
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: colors.white,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 6,
            bgcolor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 1,
            bgcolor: colors.primary,
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <DrawerHeader>
        <img src={Images.logo} alt="Takseem" width="70%" />
      </DrawerHeader>
      <List>
        {Navigation.map((item, index) =>
          item.subMenu ? (
            <Fragment>
              <ListItem
                button
                onClick={() => {
                  handleToggleSubMenu(index);
                }}
                sx={{
                  borderRadius: 2,
                  mx: 1,
                  mt: 1.5,
                  width: "94%",
                  "&.Mui-selected": {
                    backgroundImage: colors.primaryGradient,
                    bgcolor: colors.primary,
                    color: colors.white,
                    ":hover": {
                      backgroundColor: colors.primary,
                      color: colors.white,
                    },
                  },
                  ":hover": {
                    backgroundColor: colors.primary,
                    color: colors.white,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 4,
                    justifyContent: "center",
                    bgcolor: colors.white,
                    borderRadius: "6px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{}}
                />
                {expand[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={expand[index]}
                // onClick={}
              >
                <List>
                  {item.subMenu.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      button
                      selected={pathname === subItem.path}
                      onClick={() => {
                        navigate(subItem.path);
                      }}
                      sx={{
                        borderRadius: 2,
                        width: "94%",
                        mx: 1,
                        mt: 1.5,
                        "&.Mui-selected": {
                          backgroundImage: colors.primaryGradient,
                          bgcolor: colors.primary,
                          color: colors.white,
                          ":hover": {
                            backgroundColor: colors.primary,
                            color: colors.white,
                          },
                        },
                        ":hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          ml: 2,
                          mr: 4,
                          justifyContent: "center",
                          bgcolor: colors.white,
                          borderRadius: "6px",
                        }}
                      >
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText primary={subItem.label} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem key={index} disablePadding>
              <ListItemButton
                selected={
                  pathname === item.path
                    ? true
                    : pathname === item.subPath
                    ? true
                    : false
                }
                onClick={() => {
                  navigate(item.path);
                }}
                sx={{
                  borderRadius: 2,
                  mx: 1,
                  mt: 1.5,
                  "&.Mui-selected": {
                    backgroundImage: colors.primaryGradient,
                    bgcolor: colors.primary,
                    color: colors.white,
                    ":hover": {
                      backgroundColor: colors.primary,
                      color: colors.white,
                    },
                  },
                  ":hover": {
                    backgroundColor: colors.primary,
                    color: colors.white,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 4,
                    justifyContent: "center",
                    bgcolor: colors.white,
                    borderRadius: "6px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{}}
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  );
};

export default SideNav;
