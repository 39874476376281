import { get, post, patch, deleted } from "../../index";
import pharmaciesRoutes from "./Pharmacies.Routes";

const PharmaciesServices = {
  getAllPharmacies: async (rowsPerPage, page) => {
    console.log("~ rowsPerPage, page:", rowsPerPage, page);
    const data = await get(
      pharmaciesRoutes.getAllPharmacies +
        `?limit=${rowsPerPage}` +
        `&page=${page}`
    );
    return data;
  },
  createPharmacy: async (obj) => {
    const data = await post(pharmaciesRoutes.createPharmacy, obj);
    return data;
  },
  updatePharmacy: async (obj) => {
    const data = await patch(pharmaciesRoutes.updatePharmacy, obj);
    return data;
  },
  deletePharmacy: async (id) => {
    const data = await deleted(pharmaciesRoutes.deletePharmacy + `?id=${id}`);
    return data;
  },
  uploadImage: async (obj) => {
    const data = await post(pharmaciesRoutes.uploadImage, obj);
    return data;
  },
};

export default PharmaciesServices;
