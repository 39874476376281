import React, { Fragment, useEffect, useState } from 'react'
import { Layout } from './../../layout/Layout';
import { Typography, Box, Grid, FormControl, TextField } from '@mui/material';
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { InputField } from './../../components/Fields/TextField';
import { PrimaryButton } from './../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import { useForm } from 'react-hook-form';
import VoucherServices from './../../apis/Voucher/VoucherServices';

const EditVoucher = () => {
  const navigate = useNavigate();
  const { state } = useLocation()
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [voucherId, setVoucherId] = useState("");
  // const [from, setFrom] = useState("");
  // const [to, setTo] = useState("");
  // const [loader, setLoader] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);

  const updateVoucher = async (formData) => {
    try {
      let obj = {
        code: formData.code,
        discount_amount: Number(formData.discount),
        usage_limit: Number(formData.limit),
        valid_from: formData.from,
        valid_to: formData.to,
      }
      const { data, message } = await VoucherServices.updateVoucher(obj, voucherId);
      if (data === null) return
      SuccessToaster(message)
      setTimeout(() => (navigate("/vouchers")), 1000)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  //* For Default/AutoFill Values
  const defaultData = () => {
    setValue('code', state?.data.code)
    setValue('discount', state?.data.discount_amount)
    setValue('from', state?.data.valid_from.split("T").shift())
    setValue('to', state?.data.valid_to.split("T").shift())
    setValue('limit', state?.data.usage_limit)
    setVoucherId(state?.data._id)
  }
  useEffect(() => {
    defaultData()
  }, [])

  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Edit Voucher</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Vouchers', 'Edit Voucher']} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(updateVoucher)}>
            <Grid container spacing={2} alignItems="center">
              {/* GST */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Voucher Code"
                  register={register("code", {
                    required: 'Please fill all fields',
                    onChange: () => setEnableBtn(true),
                    pattern: {
                      message: 'Please fill all fields',
                    }
                  })}
                />
              </Grid>
              {/* Discount Amount */}
              <Grid item xs={12} md={6}>
                <InputField
                type="number"
                  label="Discount Amount"
                  register={register("discount", {
                    required: 'Please fill all fields',
                    onChange: () => setEnableBtn(true),
                    pattern: {
                      message: 'Please fill all fields',
                    }
                  })}
                />
              </Grid>
              {/* Usage Limit */}
              <Grid item xs={12} md={6}>
                <InputField
                type="number"
                  label="Usage Limit"
                  register={register("limit", {
                    required: 'Please fill all fields',
                    onChange: () => setEnableBtn(true),
                    pattern: {
                      message: 'Please fill all fields',
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth >
                  <TextField
                    size="small"
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true, required: true, }}
                    sx={{ mb: 2 }}
                    // sx={{ input: { cursor: 'pointer' } }}
                    variant="outlined"
                    error={errors?.from?.message && true}
                    helperText={errors?.from?.message}
                    {...register("from", {
                      onChange: () => setEnableBtn(true),
                      required: {
                        value: true,
                        message: "Please fill all fields",
                      },
                      // pattern: {message: "Enter User's First Name" },
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth >
                  <TextField
                    size="small"
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true, required: true, }}
                    sx={{ mb: 2 }}
                    // sx={{ input: { cursor: 'pointer' } }}
                    variant="outlined"
                    error={errors?.to?.message && true}
                    helperText={errors?.to?.message}
                    {...register("to", {
                      onChange: () => setEnableBtn(true),
                      required: {
                        value: true,
                        message: "Please fill all fields",
                      },
                      // pattern: {message: "Enter User's First Name" },
                    })}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {enableBtn &&
              <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {/* <SecondaryButton
                    data-testId="back-btn"
                    title="Go Back"
                    onClick={() => navigate('/')}
                  /> */}

                <PrimaryButton
                  data-testId="save-btn"
                  title={"Save changes"}
                  type="submit"
                // loading={loading}
                />
              </Box>
            }
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default EditVoucher