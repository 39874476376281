import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  CardMedia,
  Checkbox,
  FormControlLabel,
  TextField,
  Chip,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "../../../components/Fields/TextField";
import SelectField from "../../../components/Fields/SelectField";
import { PrimaryButton } from "../../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import { set, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { CloudUpload, Close } from "@mui/icons-material";
import ProductsServices from "../../../apis/Marketplace/Products/ProductsServices";
import CategoriesServices from "../../../apis/Marketplace/Categories/CategoriesServices";
import OffersServices from "../../../apis/Marketplace/Offers/OffersServices";
import PharmaciesServices from "../../../apis/Marketplace/Pharmacies/PharmaciesServices";
import { baseUrl } from "../../../../axios";

function EditProduct() {
  const [imgPath, setImgPath] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [offers, setOffers] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerName, setOfferName] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [availableIn, setAvailableIn] = useState([]);
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(1000);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("state=>", state);

  const getCategories = async () => {
    try {
      const result = await CategoriesServices.getAllCategories(
        page ? page : 1,
        rows ? rows : ""
      );
      const { data } = result;
      if (data === null) return;
      setCategories(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error=>", error);
    }
  };

  const getOffers = async () => {
    try {
      const result = await OffersServices.getAlloffers(
        page ? page : 1,
        rows ? rows : ""
      );
      const { data } = result;
      if (data === null) return;
      setOffers(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error=>", error);
    }
  };

  const getPharmacies = async () => {
    try {
      const result = await PharmaciesServices.getAllPharmacies(
        rows ? rows : "",
        page ? page : 1
      );
      const { data } = result;
      if (data === null) return;
      setPharmacies(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setAvailableIn([...availableIn, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeletePill = (availableToDelete) => {
    const updatedAvailableIn = availableIn.filter(
      (available) => available !== availableToDelete
    );
    setAvailableIn(updatedAvailableIn);
  };

  const updateProduct = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        id: state?.data?._id,
        name: formData.name,
        category_id: categoryId,
        offer_id: offerId,
        pharmacy_id: pharmacyId,
        price: formData.price,
        discounted_price: formData.discountPrice,
        discount_validity: formData.discountValidity,
        description: formData.description,
        description_ar: formData.descriptionInAr,
        picture: imgPath,
        in_stock: isChecked,
        available_in: availableIn,
      };

      const { data, message } = await ProductsServices.updateProduct(obj);
      if (data === null) return;
      setTimeout(() => navigate("/marketplace/products"), 1000);
      SuccessToaster(message);
      // console.log(data.message);
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const productPicture = async (images) => {
    try {
      const pictures = [...imgPath];
      let formData = new FormData();
      for (const image of images) {
        formData.append("imagePath", image);
      }
      const { data } = await ProductsServices.uploadImage(formData);
      if (data === null) return;
      pictures.push(data.imgPath);
      setImgPath(pictures);
      setFileInputKey(Date.now());
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleImageRemove = (url) => {
    const updatedImgPath = imgPath.filter((image) => image !== url);
    setImgPath(updatedImgPath);
  };

  const handleReset = () => {
    console.log("clicked");
    setOfferName("");
  };

  const defaultData = () => {
    setValue("name", state?.data?.name);
    setValue("category", state?.data?.category_id?.name);
    setCategoryId(state?.data?.category_id?._id);
    setValue("offer", state?.data.offer_id?.name);
    setOfferName(state?.data?.offer_id?.name);
    setOfferId(state?.data?.offer_id?._id);
    setValue("pharmacy", state?.data?.pharmacy_id?.name);
    setPharmacyName(state?.data.pharmacy_id?.name);
    setPharmacyId(state?.data.pharmacy_id?._id);
    setValue("description", state?.data?.description);
    setValue("descriptionInAr", state?.data?.description_ar);
    setValue(
      "discountValidity",
      state?.data.discount_validity?.split("T").shift()
    );
    setAvailableIn(state?.data?.available_in);
    setValue("imagePath", state?.data?.picture);
    // setValue("in_stock", state?.data?.in_stock);
    setDiscountedPrice(
      state?.data?.discounted_price === 0 ? null : state?.data?.discounted_price
    );
    setPrice(state?.data?.price);
    setIsChecked(state?.data?.in_stock);
    setImgPath(state?.data?.picture);
  };

  useEffect(() => {
    getCategories();
    getOffers();
    getPharmacies();
    defaultData();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Edit Product
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Products", "Edit Product"]} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <InputField
                  label="Name"
                  register={register("name", {
                    required: "Please fill the field",
                  })}
                  error={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField
                  label={"Select Category"}
                  data={categories}
                  setState={(id, name) => {
                    setCategoryId(id);
                    setCategoryName(name);
                  }}
                  stateValue={getValues("category")}
                  register={register("category", {
                    required: "Please select category",
                  })}
                  error={errors?.category?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <SelectField
                      label={"Select Offer"}
                      data={offers}
                      setState={(id, name) => {
                        setOfferId(id);
                        setOfferName(name);
                      }}
                      register={register("offer")}
                      stateValue={offerName}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Button fullWidth variant="outlined" onClick={handleReset}>
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField
                  label={"Select Pharmacy"}
                  data={pharmacies}
                  setState={(id, name) => {
                    setPharmacyId(id);
                    setPharmacyName(name);
                  }}
                  stateValue={getValues("pharmacy")}
                  register={register("pharmacy", {
                    required: "Please select pharmacy",
                  })}
                  error={errors?.pharmacy?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label="Price"
                  defaultValue={state?.data?.price}
                  register={register("price", {
                    required: "Please fill the field",
                  })}
                  onChange={(e) => setPrice(parseFloat(e.target.value))}
                  error={errors?.price?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label="Discounted Price"
                  defaultValue={state?.data?.discounted_price}
                  register={register("discountPrice", {
                    required:
                      state?.data?.discounted_price === 0
                        ? false
                        : "Please fill the field",
                  })}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (isNaN(value) && value > price) {
                      setValue("discountPrice", price);
                      setDiscountedPrice(price);
                    } else {
                      setDiscountedPrice(value);
                    }
                  }}
                  error={errors?.discountPrice?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  disable={!discountedPrice || discountedPrice >= price}
                  type={"date"}
                  defaultValue={
                    state?.data?.discounted_price === 0
                      ? null
                      : getValues("discountPrice")
                  }
                  register={register("discountValidity", {
                    required:
                      state?.data?.discounted_price === 0
                        ? false
                        : "Please fill the field",
                  })}
                  error={errors?.discountValidity?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label={"Description"}
                  type={"text"}
                  register={register("description", {
                    required: "Please fill the field",
                  })}
                  error={errors?.description?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label={"الوصف باللغة العربية"}
                  sx={{ direction: "rtl" }}
                  type={"text"}
                  register={register("descriptionInAr", {
                    required: "Please fill the field",
                  })}
                  error={errors?.description?.message}
                />
              </Grid>
              <Grid item md={12}>
                <Grid container gap={"5px"}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={!isChecked}
                      InputProps={{
                        inputProps: { step: 0.001, min: 0 },
                      }}
                      sx={{ mb: "0px", width: "97%" }}
                      label={"Avalable in"}
                      placeholder={"Avalaible in"}
                      type={"text"}
                      size={"small"}
                      variant="outlined"
                      value={inputValue}
                      fullWidth
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleInputKeyDown(e)}
                      register={register("availablein", {
                        required:
                          isChecked && availableIn.length === 0
                            ? "Please fill the field"
                            : false,
                      })}
                    />
                    {isChecked && (
                      <Box>
                        <Stack direction={"row"} spacing={1} mt={"10px"}>
                          {availableIn.map((item, index) => (
                            <Chip
                              key={index}
                              label={item}
                              onDelete={() => handleDeletePill(item)}
                              color="primary"
                              variant="outlined"
                            />
                          ))}
                        </Stack>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                          name="inStock"
                          color="primary"
                        />
                      }
                      label="In Stock"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <input
                  multiple
                  type="file"
                  accept="image/*"
                  key={fileInputKey}
                  style={{
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "none",
                  }}
                  id="uploadImages"
                  onInput={(event) => {
                    productPicture(event.target.files);
                  }}
                  {...register("image")}
                />
                <label
                  htmlFor="uploadImages"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    width: "100%",
                    height: "200px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                  }}
                >
                  <CloudUpload
                    sx={{
                      display: "block",
                      fontSize: "60px",
                      color: colors.primary,
                      cursor: "pointer",
                      "&:hover": {
                        color: colors.secondary,
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  />
                  <Typography sx={{ color: colors.gray }}>
                    Upload Images
                  </Typography>
                </label>
                {/* {errors?.image?.message && (
                  <Typography sx={{ color: "#d32f2f" }}>
                    {errors?.image?.message}
                  </Typography>
                )} */}
              </Grid>
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {imgPath.map((image, index) => (
                    // <CardMedia
                    //   key={index}
                    //   component={"img"}
                    //   image={baseUrl + image}
                    //   sx={{
                    //     height: "150px",
                    //     width: "150px",
                    //   }}
                    // />
                    <Box
                      key={index}
                      sx={{
                        height: "150px",
                        width: "150px",
                        position: "relative",
                        padding: "40px",
                      }}
                    >
                      <CardMedia
                        key={index}
                        component={"img"}
                        image={baseUrl + image}
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => handleImageRemove(image)}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Update"}
                type="button"
                loading={loading}
                onClick={handleSubmit(updateProduct)}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default EditProduct;
