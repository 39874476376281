import React, { Fragment, useState } from "react";
import { Typography, Box, Grid, Dialog, DialogTitle } from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "../../../components/Fields/TextField";
import { PrimaryButton } from "../../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import OffersServices from "../../../apis/Marketplace/Offers/OffersServices";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function CreateOffer() {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const createOffer = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        name: formData.offer,
        name_ar: formData.offerInAr,
        color: formData.color,
      };
      const { data, message } = await OffersServices.createOffer(obj);
      if (data === null) return;
      setTimeout(() => navigate("/marketplace/Offers"), 1000);
      SuccessToaster(message);
      console.log(data.message);
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/* <ColorDialog open={colorDialog} onClose={() => setColorDialog(false)} /> */}
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Offer
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Offers", "Create Offer"]} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(createOffer)}>
            <Grid container spacing={2} alignItems="center">
              {/* Name in Eng */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Create Offer"
                  register={register("offer", {
                    required: "Please fill the field",
                  })}
                  error={errors?.offer?.message}
                />
              </Grid>
              {/* Name in Ar */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="إنشاء العرض"
                  sx={{ direction: "rtl" }}
                  register={register("offerInAr", {
                    required: "Please fill the field",
                  })}
                  error={errors?.offerInAr?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  label={"Select Color"}
                  type={"color"}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                  register={register("color", {
                    required: "Please select color",
                  })}
                  error={errors?.color?.message}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Create"}
                type="submit"
                loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreateOffer;
