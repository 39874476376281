import React, { Fragment, useState, useEffect } from "react";
import OffersServices from "../../../apis/Marketplace/Offers/OffersServices";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Paper,
  CardMedia,
} from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { SecondaryButton } from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import {
  Create,
  DeleteForever,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import { useTheme } from "@emotion/react";
import { Loading } from "../../../components/UI/Loader";
import { baseUrl } from "../../../../axios";

function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

function Offers() {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [count, setCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const tableHead = ["Name", "Name In Ar", "Color", "Action"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOffers(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getOffers(page + 1, event.target.value);
  };

  const getOffers = async (newPage, rowsPerPage) => {
    try {
      const { data } = await OffersServices.getAlloffers(
        newPage ? newPage : 1,
        rowsPerPage ? rowsPerPage : ""
      );
      if (data === null) return;
      setOffers(data.result);
      setCount(data.count);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteOffer = async () => {
    try {
      const { data, message } = await OffersServices.deleteOffer(offerId);
      if (data === null) return;
      SuccessToaster(message);
      getOffers();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getOffers();
  }, []);
  return (
    <Fragment>
      <ConfirmationDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(!confirmDialog)}
        action={deleteOffer}
        title={"Delete this offer"}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Offers
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Offers"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                textAlign: "right",
                mt: 2,
              }}
            >
              <SecondaryButton
                title="Create Offer"
                onClick={() => navigate("/marketplace/offers/createoffer")}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          fontWeight: "bold",
                          display: index === 3 && "flex",
                          justifyContent: index === 3 && "flex-end",
                        }}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name_ar}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            backgroundColor: row.color,
                            padding: "5px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            boxShadow: "1px 1px 10px 1px #cdcdcd",
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent={"flex-end"}>
                          <Tooltip title="Edit">
                            <IconButton
                              component={Link}
                              to={`/marketplace/offers/editoffer`}
                              state={{ data: row }}
                            >
                              <Create sx={{ color: colors.secondary }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setOfferId(row._id);
                                setConfirmDialog(!confirmDialog);
                              }}
                            >
                              <DeleteForever sx={{ color: colors.opal }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
}

export default Offers;
