import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  CardMedia,
  Checkbox,
  FormControlLabel,
  TextField,
  Chip,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "../../../components/Fields/TextField";
import SelectField from "../../../components/Fields/SelectField";
import { PrimaryButton } from "../../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Close, CloudUpload } from "@mui/icons-material";
import ProductsServices from "../../../apis/Marketplace/Products/ProductsServices";
import CategoriesServices from "../../../apis/Marketplace/Categories/CategoriesServices";
import OffersServices from "../../../apis/Marketplace/Offers/OffersServices";
import PharmaciesServices from "../../../apis/Marketplace/Pharmacies/PharmaciesServices";
import { baseUrl } from "../../../../axios";
import { OutlineInputField } from "../../../components/Fields/TextField";
import { Error } from "../../../components/UI/Error";

function CreateProduct() {
  const [imgPath, setImgPath] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [offers, setOffers] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerName, setOfferName] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [availableIn, setAvailableIn] = useState([]);
  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(1000);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    resetField,
  } = useForm();
  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      const result = await CategoriesServices.getAllCategories(
        page ? page : 1,
        rows ? rows : ""
      );
      const { data } = result;
      if (data === null) return;
      setCategories(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error=>", error);
    }
  };
  console.log("categories=>", categories);

  const getOffers = async () => {
    try {
      const result = await OffersServices.getAlloffers(
        page ? page : 1,
        rows ? rows : ""
      );
      const { data } = result;
      if (data === null) return;
      setOffers(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error=>", error);
    }
  };
  console.log("offers=>", offers);

  const getPharmacies = async () => {
    try {
      const result = await PharmaciesServices.getAllPharmacies(
        rows ? rows : "",
        page ? page : 1
      );
      const { data } = result;
      if (data === null) return;
      setPharmacies(data.result);
      setRows(data.result);
    } catch (error) {
      console.log("error =>", error);
    }
  };
  console.log("pharmacies=>", pharmacies);

  const handleInputKeyDown = (event) => {
    console.log(event.target?.value);
    if (event.key === "Enter" && event.target?.value.trim() !== "") {
      setAvailableIn([...availableIn, event.target?.value.trim()]);
      resetField("availablein");
    }
  };

  const handleDeletePill = (availableToDelete) => {
    const updatedAvailableIn = availableIn.filter(
      (available) => available !== availableToDelete
    );
    setAvailableIn(updatedAvailableIn);
  };

  const createProduct = async (formData) => {
    console.log(formData);
    setLoading(true);
    try {
      let obj = {
        name: formData.name,
        category_id: categoryId,
        offer_id: offerId,
        price: price,
        discounted_price: discountedPrice,
        discount_validity: formData.discountValidity,
        picture: imgPath,
        pharmacy_id: pharmacyId,
        description: formData.description,
        description_ar: formData.descriptionInAr,
        in_stock: isChecked,
        available_in: availableIn,
      };
      const result = await ProductsServices.createProduct(obj);
      const { data, message } = result;
      if (data === null) return;
      setTimeout(() => navigate("/marketplace/products"), 1000);
      SuccessToaster(message);
      console.log(obj);
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const productPicture = async (images) => {
    console.log(images);
    try {
      const pictures = [...imgPath];
      let formData = new FormData();
      for (const image of images) {
        formData.append("imagePath", image);
      }
      const { data, message } = await ProductsServices.uploadImage(formData);
      if (data === null) return;
      pictures.push(data.imgPath);
      setImgPath(pictures);

      setFileInputKey(Date.now());
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleImageRemove = (url) => {
    const updatedImgPath = imgPath.filter((item) => item !== url);
    setImgPath(updatedImgPath);
  };

  const handleReset = () => {
    setOfferName("");
  };

  useEffect(() => {
    getCategories();
    getOffers();
    getPharmacies();
  }, []);

  // console.log(handleSubmit);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Product
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Products", "Create Product"]} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(createProduct)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <InputField
                  label="Name"
                  register={register("name", {
                    required: "Please fill the field",
                  })}
                  error={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField
                  label={"Select Category"}
                  data={categories}
                  setState={(id, name) => {
                    setCategoryId(id);
                    setCategoryName(name);
                  }}
                  stateValue={categoryName}
                  register={register("category", {
                    required: "Please select category",
                  })}
                  error={errors?.category?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <SelectField
                      label={"Select Offer"}
                      data={offers}
                      setState={(id, name) => {
                        setOfferId(id);
                        setOfferName(name);
                      }}
                      stateValue={offerName}
                      register={register("offer")}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      fullWidth
                      sx={{ height: "73%" }}
                      variant="outlined"
                      onClick={handleReset}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField
                  label={"Select Pharmacy"}
                  data={pharmacies}
                  setState={(id, name) => {
                    setPharmacyId(id);
                    setPharmacyName(name);
                  }}
                  stateValue={pharmacyName}
                  register={register("pharmacy", {
                    required: "Please select pharmacy",
                  })}
                  error={errors?.pharmacy?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label="Price"
                  register={register("price", {
                    required: "Please fill the field",
                  })}
                  onChange={(e) => setPrice(parseFloat(e.target.value))}
                  error={errors?.price?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label="Discounted Price"
                  register={register("discountPrice")}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value > price) {
                      setValue("discountPrice", price);
                      setDiscountedPrice(price);
                    } else {
                      setDiscountedPrice(value);
                    }
                  }}
                  error={errors?.discountPrice?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  disable={!discountedPrice || discountedPrice >= price}
                  type={"date"}
                  register={
                    discountedPrice &&
                    register("discountValidity", {
                      required: "Please fill the field",
                    })
                  }
                  error={errors?.discountValidity?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  label={"Description"}
                  type={"text"}
                  register={register("description", {
                    required: "Please fill the field",
                  })}
                  error={errors?.description?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  sx={{ direction: "rtl" }}
                  label={"الوصف باللغة العربية"}
                  type={"text"}
                  register={register("descriptionInAr", {
                    required: "Please fill the field",
                  })}
                  error={errors?.descriptionInAr?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container gap={"5px"}>
                  <Grid item md={4}>
                    <TextField
                      disabled={!isChecked}
                      InputProps={{
                        inputProps: { step: 0.001, min: 0 },
                      }}
                      sx={{ mb: "0px", width: "97%" }}
                      size={"small"}
                      variant="outlined"
                      label={"Available in"}
                      placeholder={"Available in"}
                      fullWidth
                      error={errors?.availablein?.message}
                      {...register("availablein", {
                        required:
                          isChecked && availableIn.length === 0
                            ? "Please fill the field"
                            : false,
                      })}
                      onKeyUp={handleInputKeyDown}
                    />
                    {/* <TextField
                      disabled={!isChecked}
                      label={"Avalable in"}
                      InputLabelProps={{ size: "small", paddingTop: 4 }}
                      type={"text"}
                      variant="outlined"
                      // value={inputValue}
                      fullWidth
                      onKeyUp={handleInputKeyDown}
                      error={errors?.availablein?.message}
                      {...register("availablein", {
                        required:
                          isChecked && availableIn.length === 0
                            ? "Please fill the field"
                            : false,
                      })}
                      sx={{
                        width: "97%",
                        // "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":

                        // padding: "9px",
                      }}
                    /> */}
                    {errors.availablein && (
                      <Error message={errors?.availablein?.message} />
                    )}
                    <Box>
                      <Stack direction={"row"} spacing={1} mt={"10px"}>
                        {availableIn.map((item, index) => (
                          <Chip
                            key={index}
                            label={item}
                            onDelete={() => handleDeletePill(item)}
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box>
                      <FormControlLabel
                        sx={{
                          padding: "0px",
                          margin: "0px",
                          color: colors.gray,
                        }}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                            name="inStock"
                            color="primary"
                          />
                        }
                        label="In Stock"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <input
                  multiple
                  type="file"
                  accept="image/*"
                  key={fileInputKey}
                  style={{
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "none",
                  }}
                  id="uploadImages"
                  onInput={(event) => {
                    productPicture(event.target.files);
                  }}
                  {...register("image", { required: "Please upload image" })}
                />
                <label
                  htmlFor="uploadImages"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    width: "100%",
                    height: "200px",
                    border: errors?.image?.message
                      ? "1px solid #d32f2f"
                      : "1px solid #c4c4c4",
                    borderRadius: "4px",
                  }}
                >
                  <CloudUpload
                    sx={{
                      display: "block",
                      fontSize: "38px",
                      color: errors?.image?.message
                        ? "#d32f2f"
                        : colors.primary,
                      cursor: "pointer",
                      "&:hover": {
                        color: colors.secondary,
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: errors?.image?.message ? "#d32f2f" : colors.gray,
                    }}
                  >
                    {errors?.image ? errors?.image?.message : "Upload Image"}
                  </Typography>
                </label>
              </Grid>
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {imgPath?.map((imageUrl, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: "150px",
                        width: "150px",
                        position: "relative",
                        padding: "40px",
                      }}
                    >
                      <CardMedia
                        key={index}
                        component={"img"}
                        image={baseUrl + imageUrl}
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => handleImageRemove(imageUrl)}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Create"}
                type="button"
                loading={loading}
                onClick={handleSubmit(createProduct)}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreateProduct;
