import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "../../layout/Layout";
import colors from "../../styles/colors";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import OrderServices from "./../../apis/Order/OrderServices";
import UserInfoDialog from "../../components/Dialog/UserInfoDialog";
import QuoteDialog from "../../components/Dialog/QuoteDialog";
import MedicineDialog from "../../components/Dialog/MedicineDialog";
//* For Logs
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
//* Required Components
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import { getTime, getTimeDate } from "./../../utils/DateFormate";
import { baseUrl } from "../../../axios";
import { Loading } from "../../components/UI/Loader";
import { SecondaryButton } from "../../components/Button/Button";
//* Firebase Import
import { onMessageListener } from "../../firebase/firebase";
//* MUI Imports
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Avatar,
  IconButton,
  Link,
} from "@mui/material";
import moment from "moment";

const tableHead = ["Image", "Name", "Quantity"];
const OrderDetail = () => {
  const { id } = useParams();

  // *For Loading
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Order Detail
  const [orderDetail, setOrderDetail] = useState([]);
  console.log("🚀 orderDetail:", orderDetail);
  const [infoModal, setInfoModal] = useState(false);
  const [medicineModal, setMedicineModal] = useState(false);
  const [medImg, setMedImg] = useState("");

  //* For Different Image URL's
  const imageURL = orderDetail?.result?.prescription?.startsWith("https")
    ? orderDetail?.result?.prescription
    : baseUrl + orderDetail?.result?.prescription;

  //* Order Details Api
  const getOrderDetail = async () => {
    try {
      const { status, responseCode, data } =
        await OrderServices.getOrderDetails(id);
      if (status === true && responseCode === 200) {
        setOrderDetail(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  //* Quote Orders
  const [quoteDialog, setQuoteDialog] = useState(false);
  const quoteOrder = async (quote, reset) => {
    try {
      const obj = {
        order_id: id,
        total_amount: quote.price,
        message: quote.message,
      };
      const { status, responseCode } = await OrderServices.quoteOrder(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster("Offer Quoted");
        setQuoteDialog(!quoteDialog);
        reset();
        getOrderDetail();
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* for image download
  const [fetching, setFetching] = useState(false);
  const imgDownload = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.log(error));
  };

  function capitalizeFirstLetter(inputString) {
    return `${inputString.charAt(0).toUpperCase()}${inputString.slice(1)}`;
  }

  //* FireBase for Reload Page
  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ file: Header.js:57 ~ .then ~ payload", payload);
      getOrderDetail();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Fragment>
      <UserInfoDialog
        open={infoModal}
        onClose={() => setInfoModal(!infoModal)}
        action={orderDetail?.result?.user}
      />
      <MedicineDialog
        open={medicineModal}
        onClose={() => setMedicineModal(!medicineModal)}
        image={medImg}
        download={imgDownload}
      />
      <QuoteDialog
        open={quoteDialog}
        onClose={() => setQuoteDialog(!quoteDialog)}
        title={"Quote Offer"}
        action={quoteOrder}
        loading={""}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Order Detail
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Order", "Order Detail"]} />
          </Grid>
        </Grid>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                backgroundColor: colors.white,
                p: 2,
                my: 2,
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">
                Order ID: <b>{orderDetail?.result?.order_id}</b>
              </Typography>
              <Box display="flex" alignItems="center" p={1}>
                <Typography variant="body2" px={1}>
                  Order Status{" "}
                </Typography>
                <Chip
                  label={orderDetail?.result?.status?.name}
                  sx={{
                    backgroundImage: colors.primaryGradient,
                    color: colors.white,
                  }}
                />
              </Box>
              <Typography variant="body1">
                Placed at: <b>{getTimeDate(orderDetail?.result?.date_time)}</b>
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    backgroundColor: colors.white,
                    p: 2,
                    borderRadius: "12px",
                    minHeight: "200px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      User Details
                    </Typography>
                    {orderDetail?.result?.address?.tag && (
                      <Chip
                        label={orderDetail?.result?.address?.tag}
                        sx={{
                          backgroundImage: colors.secondaryGradient,
                          color: colors.white,
                        }}
                      />
                    )}
                  </Box>
                  <Divider flexItem sx={{ my: 1 }} />
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item md={12}>
                      <Box sx={{ my: 2 }}>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Name: </b>
                          {orderDetail?.result?.user?.name ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Email: </b>
                          {orderDetail?.result?.user?.deleted_email
                            ? orderDetail?.result?.user?.deleted_email
                            : orderDetail?.result?.user?.email}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Area: </b>
                          {orderDetail?.result?.address?.area ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>House/Building: </b>
                          {orderDetail?.result?.address?.house_building ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Apt/Room: </b>
                          {orderDetail?.result?.address?.apt_room ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Street: </b>
                          {orderDetail?.result?.address?.street ?? "--"}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                          <b>Address: </b>
                          {orderDetail?.result?.address?.address ?? "--"}
                        </Typography>
                        {orderDetail?.result?.address?.notes && (
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            <b>Notes: </b>
                            {orderDetail?.result?.address?.notes ?? "--"}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="space-evenly">
                        <Box>
                          <SecondaryButton
                            title={"View User Docs"}
                            onClick={() => setInfoModal(!infoModal)}
                          />
                        </Box>
                        <Box>
                          <SecondaryButton
                            title={"View Location"}
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/place/${orderDetail?.result?.address?.latitude},${orderDetail?.result?.address?.longitude}/`,
                                "_blank"
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: colors.white,
                        p: 2,
                        borderRadius: "12px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Billing Details
                        </Typography>
                        {["Confirmed", "Completed"].includes(
                          orderDetail?.result?.status?.name
                        ) && (
                          <Chip
                            label={
                              orderDetail?.billing?.payment_method === "card"
                                ? "Paid Online"
                                : "Cash on delivery"
                            }
                            sx={{
                              backgroundImage: colors.quoted,
                              color: colors.white,
                            }}
                          />
                        )}
                      </Box>
                      <Divider flexItem sx={{ my: 1 }} />
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Amount:</Typography>
                          <Typography>
                            <b>{orderDetail?.billing?.amount}</b>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Delivery: </Typography>
                          <Typography>
                            <b>{orderDetail?.billing?.delivery_charges}</b>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Tip: </Typography>
                          <Typography>
                            <b>{orderDetail?.billing?.tip ?? 0}</b>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">Discount: </Typography>
                          <Typography>
                            <b>{orderDetail?.billing?.discount ?? 0}</b>
                          </Typography>
                        </Box>
                        {orderDetail?.billing?.voucher_code && (
                          <Box
                            sx={{
                              my: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">
                              Voucher Code:{" "}
                            </Typography>
                            <Typography>
                              <b>{orderDetail?.billing?.voucher_code ?? 0}</b>
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2">
                            Total Amount:{" "}
                          </Typography>
                          <Typography>
                            <b>{orderDetail?.billing?.total_amount}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {orderDetail?.result?.delivery && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: colors.white,
                          p: 2,
                          borderRadius: "12px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body1" fontWeight="bold">
                            Delivery Slot
                          </Typography>
                          {/* {["Confirmed", "Completed"].includes(
                          orderStatus.name
                        ) && (
                          <Chip
                            label={
                              billingInfo.payment_method === "card"
                                ? "Paid Online"
                                : "Cash on delivery"
                            }
                            sx={{
                              backgroundImage: colors.quoted,
                              color: colors.white,
                            }}
                          />
                        )} */}
                        </Box>
                        <Divider flexItem sx={{ my: 1 }} />
                        <Box>
                          <Box
                            sx={{
                              width: "100%",
                              mt: 2,
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">
                              Date & Time:
                            </Typography>
                            <Typography>
                              <b>
                                {moment(
                                  orderDetail?.result?.delivery?.date_time
                                )
                                  .utc()
                                  .format(
                                    orderDetail?.result?.delivery?.nature ===
                                      "instant"
                                      ? "MMM DD, hh:mm a"
                                      : "MMM DD,"
                                  )}
                              </b>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              my: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">Slot: </Typography>
                            {orderDetail?.result?.delivery.nature ===
                            "instant" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography>
                                  <b>Instant</b>
                                </Typography>
                                <Typography
                                  sx={{ color: "#C4C4C4", fontSize: "11px" }}
                                >
                                  With in 4 hours
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography>
                                  <b>
                                    {capitalizeFirstLetter(
                                      orderDetail?.result?.delivery?.slot
                                    )}
                                  </b>
                                </Typography>
                                <Typography
                                  sx={{ color: "#C4C4C4", fontSize: "11px" }}
                                >
                                  {orderDetail?.result?.delivery?.time_range}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {orderDetail?.result?.medication?.length ? (
              <Box
                sx={{
                  backgroundColor: colors.white,
                  p: 2,
                  my: 2,
                  borderRadius: "12px",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Medicine
                </Typography>
                <Divider flexItem sx={{ my: 1 }} />
                <TableContainer
                  component={Paper}
                  sx={{
                    "&::-webkit-scrollbar": {
                      bgcolor: colors.white,
                      height: "8px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      bgcolor: colors.primary,
                      borderRadius: "10px",
                      cursor: "pointer",
                    },
                    "&.MuiPaper-root": {
                      borderRadius: "12px",
                      mt: 2,
                      boxShadow: "rgb(32 40 45 / 8%) 0px 2px 14px 0px",
                    },
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableHead.map((item, index) => (
                          <TableCell
                            key={index}
                            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                          >
                            {item}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? orderDetail?.result?.medication?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : orderDetail?.medication
                      )?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row.picture ? (
                              <IconButton
                                onClick={() => {
                                  setMedImg(row.picture);
                                  setMedicineModal(!medicineModal);
                                }}
                              >
                                <Avatar
                                  src={baseUrl + row.picture}
                                  alt="Medicine"
                                />
                              </IconButton>
                            ) : null}
                          </TableCell>
                          <TableCell sx={{}}>{row.name}</TableCell>
                          <TableCell sx={{ pl: 4 }}>{row.qty}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: colors.white,
                  p: 2,
                  my: 2,
                  borderRadius: "12px",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Prescription
                </Typography>
                <Divider flexItem sx={{ my: 1 }} />
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box sx={{ position: "relative", p: 1 }}>
                      <img
                        src={imageURL ?? imageURL}
                        alt="prescriptionImg"
                        style={{ width: "100%", height: "380px" }}
                      />
                      <Tooltip title="download">
                        <Link
                          sx={{
                            boxShadow:
                              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
                            cursor: "pointer",
                            bgcolor: "#ffffffc9",
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 40,
                            height: 40,
                            borderRadius: 40,
                            right: 10,
                            top: 10,
                            "&:hover": { bgcolor: colors.cloud },
                          }}
                          disabled={fetching}
                          onClick={() => imgDownload(imageURL, "prescription")}
                        >
                          <SystemUpdateAltIcon
                            sx={{ fontSize: "24px", color: colors.secondary }}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mx: 0.5, my: 2 }}
                  />
                  <Grid item xs={5}>
                    <Typography variant="body1" fontWeight="bold">
                      Description
                    </Typography>
                    {orderDetail?.result?.description
                      .split("\n")
                      .map((item, id) => (
                        <Box py={1} key={id}>
                          <Typography variant="body1">{item}</Typography>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box
              sx={{
                backgroundColor: colors.white,
                p: 2,
                my: 2,
                borderRadius: "12px",
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">
                  Order Logs
                </Typography>
                {["Requested", "Cancelled"].includes(
                  orderDetail?.result?.status?.name
                ) ? (
                  <SecondaryButton
                    title="Quote Order"
                    onClick={() => {
                      setQuoteDialog(!quoteDialog);
                    }}
                  />
                ) : null}
              </Box>
              {orderDetail?.logs?.map((item, index) => (
                <Timeline
                  key={index}
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {getTime(item.createdAt)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: "14px", px: 2 }}>
                      <Typography sx={{ color: colors.primary }}>
                        {item.message ?? "-"}
                      </Typography>
                      <Typography>{item.log ?? "-"}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default OrderDetail;
