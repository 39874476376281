import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//* Component Imports
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import { Layout } from "./../../layout/Layout";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import UsersServices from "../../apis/Users/UsersServices";
import UserIdCardDialog from "../../components/Dialog/UserIdCardDialog";
import DataFilter from "./../../components/Filter/DataFilter";
import { ErrorToaster, SuccessToaster } from "./../../components/Toaster/Toaster";
import { Loading } from "./../../components/UI/Loader";
import { getDate } from "../../utils/DateFormate";
import { SecondaryButton } from "../../components/Button/Button";
import colors from "../../styles/colors";
//* MUI Imports
import { useTheme } from "@emotion/react";
import { DeleteForever, KeyboardArrowLeft, KeyboardArrowRight, Visibility } from "@mui/icons-material";
import { Paper, Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton, Switch } from "@mui/material";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

const tableHead = ["Registration Date", "Name", "Email", "Phone No.", "Activate", "Emirates ID view", "Insurance Card View", "Action"];
const UsersList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Loader
  const [loader, setLoader] = useState(true);
  const [idCardModal, setIdCardModal] = useState(false);
  const [idImg, setIdImg] = useState([]);
  const [title, setTitle] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [count, setCount] = useState("");
  const [checkName, setCheckName] = useState("");
  const [idImage, setIdImage] = useState("");

  // *For Status
  // const [isActive, setIsActive] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllUser(checkName ? checkName : "", newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getAllUser(checkName ? checkName : "", page + 1, event.target.value);
  };

  //* Name Filter function
  const sortByName = (stat, id, name) => {
    setCheckName(name);
    setRowsPerPage(10);
    setPage(0);
    getAllUser(name ? name : "");
  };

  //* All Users List
  const getAllUser = async (name, newPage, rowsPerPage) => {
    setLoader(true);
    try {
      const { data, responseCode, status } = await UsersServices.getAllUsers(name ? name : "", newPage ? newPage : 1, rowsPerPage ? rowsPerPage : "");
      if (status === true && responseCode === 200) {
        setAllUsers(data.result);
        setCount(data.count);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //* For delete users API
  const [userId, setUserId] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);

  const userDelete = async () => {
    try {
      const { data } = await UsersServices.deleteUser(userId);
      if (data === null) return;
      SuccessToaster("User Deleted");
      getAllUser();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  //* For Users Status
  const updateUserStatus = async (id, active) => {
    try {
      // const index = isActive.indexOf(id);
      // const newStatus = [...isActive];
      // let active
      // if (index === -1) {
      //   newStatus.push(id);
      //   active = true
      // } else {
      //   newStatus.splice(index, 1);
      //   active = false
      // }
      // setIsActive(newStatus)
      let obj = {
        status: active ? "inactive" : "active",
      };
      const { status, responseCode, message } = await UsersServices.updateUserStatus(id, obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message, 1500);
        getAllUser();
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  return (
    <Fragment>
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(!confirmDialog)} action={userDelete} title={"Delete This User"} />
      <UserIdCardDialog
        open={idCardModal}
        onClose={() => {
          setIdCardModal(!idCardModal);
          setIdImg([]);
        }}
        image={idImg}
        title={title}
        user={idImage}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Users List
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={["Users"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 2, borderRadius: "12px", textAlign: "right", mt: 2 }}>
              <SecondaryButton title="View Deleted Users" onClick={() => navigate("/deleted-users")} />
            </Box>
            <Box>
              <DataFilter sortData={(stat, id, name) => sortByName(stat, id, name)} data={""} type={"user"} />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {getDate(row.createdAt)}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.name ? row.name : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.email ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.phone ? row.phone : "no phone"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Switch
                          size="small"
                          checked={row.is_active}
                          // checked={isActive.indexOf(row._id) !== -1}
                          onClick={() => updateUserStatus(row._id, row.is_active)}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setIdCardModal(!idCardModal);
                              setIdImg([row.nic_front, row.nic_back]);
                              setIdImage(row._id);
                              setTitle("Emirates Id");
                            }}
                          >
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setIdCardModal(!idCardModal);
                              setIdImg([row.insurance]);
                              setTitle("Insurance Card");
                              setIdImage(row._id);
                            }}
                          >
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setUserId(row._id);
                                setConfirmDialog(!confirmDialog);
                              }}
                            >
                              <DeleteForever sx={{ color: colors.opal }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default UsersList;
