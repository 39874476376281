import { useState } from "react";

function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem('token'));
  const [userName, setUserName] = useState(localStorage.getItem('name'))
  const [picture, setPicture] = useState(localStorage.getItem('picture'));
  const [phone, setPhone] = useState(localStorage.getItem('phone'));
  const [mail, setMail] = useState(localStorage.getItem('mail'));

  // *User
  const userLogin = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('name', data.name)
    localStorage.setItem('picture', data.picture)
    localStorage.setItem('phone', data.phone)
    localStorage.setItem('mail', data.email)
    setUser(data.token)
    setUserName(data.name)
    setPicture(data.picture)
    setPhone(data.phone)
    setMail(data.mail)
  };

  const userLogout = async () => {
    setUser(null)
    localStorage.clear()
  };

  // *Verify Token
  const verifyToken = () => {
    const token = localStorage.getItem('token')
    const name = localStorage.getItem('name')
    setUser(token)
    setUserName(name)
  };

  return {
    user,
    userName,
    picture,
    phone,
    mail,
    userLogin,
    userLogout,
    verifyToken
  };
}

export default useProvideAuth;