import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Error } from './../UI/Error';


function SelectField({ data, label, error, register, disable, stateValue, setState }) {
  return (
    <FormControl size="small" fullWidth variant="outlined" >
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disable}
        value={stateValue ?? ""}
        label={label}
        sx={{
          textAlign: "left",
          mb: error ? "0px" : 2
        }}
        error={error && true}
        {...register}
      >
        {data?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.name ? item.name : item}
            onClick={() => { setState(item._id, item.name) }}
          >
            {item.name ? item.name : item}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Error message={error} />
      )}
    </FormControl>
  )
}

export default SelectField