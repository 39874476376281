import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Grid, CardMedia } from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "../../../components/Fields/TextField";
import { PrimaryButton } from "../../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import CategoriesServices from "../../../apis/Marketplace/Categories/CategoriesServices";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import { baseUrl } from "../../../../axios";
import { Error } from "../../../components/UI/Error";
import { getValue } from "@testing-library/user-event/dist/utils";

function EditCategory() {
  const [selectedImage, setSelectedImage] = useState("");
  const [imgPath, setImgPath] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const updateCategory = async (formData) => {
    try {
      let obj = {
        id: state?.data?._id,
        name: formData.name,
        name_ar: formData.nameInAr,
        picture: imgPath,
      };
      const { message, responseCode } = await CategoriesServices.updatecategory(
        obj
      );
      if (responseCode === 200) {
        SuccessToaster(message);
        setTimeout(() => navigate("/marketplace/categories"), 1000);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    }
  };

  const categoryPicture = async (image) => {
    try {
      let formData = new FormData();
      formData.append("imagePath", image);
      const { data } = await CategoriesServices.uploadImage(formData);
      if (data === null) return;
      setImgPath(data.imgPath);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  console.log(state);
  const defaulData = () => {
    setValue("name", state?.data?.name);
    setValue("nameInAr", state?.data?.name_ar);
    setValue("image", state?.data?.picture);
  };

  useEffect(() => {
    defaulData();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Edit Category
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Categories", "Edit Category"]} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography
            component={"form"}
            onSubmit={handleSubmit(updateCategory)}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent={selectedImage && "center"}
            >
              {/* Name in Eng */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Create Category"
                  register={register("name", {
                    required: "Please fill the field",
                  })}
                  error={errors?.name?.message}
                />
              </Grid>
              {/* Name in Arabic */}
              <Grid item xs={12} md={6}>
                <InputField
                  sx={{ direction: "rtl" }}
                  label="إنشاء فئة"
                  register={register("nameInAr", {
                    required: "Please fill the field",
                  })}
                  error={errors?.nameInAr?.message}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display={selectedImage ? "none" : "block"}
                sx={{ padding: "0 !important" }}
              >
                <input
                  // multiple
                  type="file"
                  accept="image/*"
                  style={{
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "none",
                  }}
                  id="uploadImage"
                  onInput={(event) => {
                    categoryPicture(event.target.files[0]);
                    setSelectedImage(
                      selectedImage === ""
                        ? getValues("image")
                        : URL.createObjectURL(event.target.files[0])
                    );
                  }}
                  {...register("image")}
                  error={errors?.image?.message}
                />
                <label
                  htmlFor="uploadImage"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "15px",
                    paddingLeft: "15px",
                    cursor: "pointer",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                  }}
                >
                  <CloudUpload
                    sx={{
                      display: selectedImage ? "none" : "block",
                      fontSize: "38px",
                      color: colors.primary,
                      cursor: "pointer",
                      "&:hover": {
                        color: colors.secondary,
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  />
                  {selectedImage === "" && (
                    <Typography sx={{ color: colors.gray }}>
                      Upload Image
                    </Typography>
                  )}
                </label>
                {/* {errors?.image && <Error message={} />} */}
              </Grid>
              <Grid item md={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    component={"img"}
                    image={
                      !selectedImage
                        ? baseUrl + state?.data?.picture
                        : selectedImage
                    }
                    sx={{
                      height: "150px",
                      width: "150px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <SecondaryButton
                    data-testId="back-btn"
                    title="Go Back"
                    onClick={() => navigate('/')}
                  /> */}

              <PrimaryButton
                data-testId="save-btn"
                title={"Update"}
                type="submit"
                // loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default EditCategory;
