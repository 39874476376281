import React, { useState } from 'react'
import { Box, Button, Collapse, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { FilterAltTwoTone, Search } from '@mui/icons-material'
import colors from './../../styles/colors';
import SelectField from './../Fields/SelectField';
import { InputField } from './../Fields/TextField';
import { Fragment } from 'react';


function DataFilter({ data, sortData, type }) {

  const [filterCollapse, setFilterCollapse] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const dataType = [{ _id: "medication", name: "Medication" }, { _id: "prescription", name: "Prescription" }]

  const [selectedName, setSelectedName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState("");

  const filterData = () => {
    sortData(selectedId, orderId, name, selectedTypeId, fromDate, toDate)
  }

  const clearData = () => {
    sortData("")
    setSelectedName("")
    setFilterCollapse(!filterCollapse)
  }

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight="bold" color="initial">Filters</Typography>
        <IconButton onClick={() => setFilterCollapse(!filterCollapse)} color="primary" sx={{ bgcolor: colors.primaryLight }}>
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse} >
        <Grid container spacing={2} justifyContent="space-between" >
          {type === "order" &&
            <Fragment>
              <Grid item xs={3} ml={1}>
                <FormControl>
                  <TextField
                    label="User Name"
                    size={"small"}
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} ml={1}>
                <SelectField
                  setState={(item, name) => { setSelectedId(item); setSelectedName(name) }}
                  stateValue={selectedName}
                  data={data}
                  label={"Select Status"}
                />
              </Grid>
              <Grid item xs={3} ml={1}>
                <SelectField
                  setState={(item, name) => { setSelectedTypeId(item); setSelectedType(name) }}
                  stateValue={selectedType}
                  data={dataType}
                  label={"Select Order Type"}
                />
              </Grid>
              <Grid item xs={3} ml={1} >
                <FormControl>
                  <TextField
                    label="Order Id"
                    size={"small"}
                    variant="outlined"
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} container alignItems="center" >
                <Grid item xs={3} mr={0.5} >
                  <FormControl size="small" fullWidth variant="outlined">
                    <TextField
                      label="From Date"
                      size={"small"}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true, }}
                      onChange={(e) => (setFromDate(e.target.value))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} >
                  <FormControl size="small" fullWidth variant="outlined">
                    <TextField
                      label="To Date"
                      size={"small"}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true, }}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Fragment>
          }
          {type === "user" &&
            <Fragment>
              <Grid item xs={3} ml={5}>
                <FormControl>
                  <TextField
                    label="User Name"
                    size={"small"}
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Fragment>
          }
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              onClick={clearData}
              variant="outlined"
              sx={{

                mx: 1,
                textTransform: 'capitalize',
                minWidth: '100px',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              }}
            >Clear</Button>
            <Button
              onClick={filterData}
              variant="contained"
              sx={{
                textTransform: 'capitalize',
                minWidth: '100px',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              }}
            >Search</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default DataFilter