import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Layout } from './../../layout/Layout';
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { InputField } from './../../components/Fields/TextField';
import { PrimaryButton } from './../../components/Button/Button';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import VoucherServices from './../../apis/Voucher/VoucherServices';
import { Typography, Box, Grid, FormControl, TextField, } from '@mui/material';
import PharmacyServices from '../../apis/Pharmacy/PharmacyServices';
import AddressForm from '../../components/Dialog/AddressForm';

const CreatePharmacy = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  // *For Dialog
  const [addressFormDialog, setAddressFormDialog] = useState(false);

  const createPharmacy = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        latitude: latitude,
        longitude: longitude,
      }
      const { data, message } = await PharmacyServices.createPharmacy(obj);
      if (data === null) return
      SuccessToaster(message)
      setTimeout(() => (navigate("/pharmacies")), 1000)
    }
    catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddress = (data) => {
    try {
      setValue('address', data?.address)
      setLatitude(data.latitude)
      setLongitude(data.longitude)
    } catch (error) {
      console.log('file: create-pharmacy.js:50 => handleAddress => error:', error)
    }
  }

  return (
    <Fragment>

      {/* ========== Address Form Dialog Box ========== */}
      <AddressForm open={addressFormDialog} onClose={() => setAddressFormDialog(false)} defaultData={{ address: getValues('address'), latitude: latitude, longitude: longitude }} save={(data) => { handleAddress(data); setAddressFormDialog(false) }} />

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Create Pharmacy</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Pharmacies', 'Create Pharmacy']} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(createPharmacy)}>
            <Grid container spacing={2} alignItems="center">
              {/* GST */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Pharmacy name"
                  error={errors?.name?.message}
                  register={register("name", {
                    required: 'Please enter name',
                  })}
                />
              </Grid>
              {/* VAT */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Email"
                  error={errors?.email?.message}
                  register={register("email", {
                    required: 'Please enter email',
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex", gap: '5px', alignItems: 'flex-start' }}>
                {/* <Typography variant="subtitle1">
                  +971
                </Typography> */}
                <InputField
                  variant="standard"
                  label="Phone"
                  inputProps={{ minLength: 9, maxLength: 9 }}
                  placeholder={"50 123 4567"}
                  error={errors?.phone?.message}
                  register={register("phone", {
                    required: 'Please enter phone number',
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ cursor: 'pointer' }} onClick={() => setAddressFormDialog(true)}>
                  <InputField
                    label="Address"
                    placeholder='Address'
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.address?.message}
                    register={register("address", {
                      required: 'Please enter address',
                    })}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <PrimaryButton
                title={"Create"}
                type="submit"
                loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default CreatePharmacy